.header-text {
  border-bottom: 1px solid $brand-secondary;
  text-align: left;
  font-size: 32px;
  line-height: 36px;
  font-weight: 300;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 32px;
  border-radius: 10px 10px 0 0;
}

.wishes-wrapper {
  .footer-text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    font-size: 22px;
    font-weight: 400;
    padding: 10px 15px;
    line-height: 24px;
    min-height: 42px;
    border-top: 1px solid $brand-secondary;
    border-radius: 0 0 10px 10px;
    box-shadow: 2px 2px 12px $brand-box-shadow-color;
    background-color: lighten($brand-secondary, $contrast-high);
    color: $brand-primary;

    > .btn {
      padding: 0 12px;
    }

    > .btn:only-child {
      margin-left: auto;
    }
  }
}

@media screen and (min-width: $screen-sm-min) {
  .header-text {
    font-size: 40px;
    line-height: 42px;
  }

  .wishes-wrapper {
    .footer-text {
      border-top: none;
      box-shadow: none;

      > .btn {
        padding: 0 30px;
      }
    }
  }
}
