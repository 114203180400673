/** Removes user select */
@mixin noselect() {
  user-select: none;
}

/** Forces GPU rendering */
@mixin forceGPU() {
  // fixes webkit repaint bug
  // on fixed elements
  transform: scale3d(1, 1, 1);
}
