.wishes-summary {
  padding-bottom: 108px;
  h3 {
    font-size: 28px;
  }
}

.wishes-details {
  padding-bottom: 0;
}

.button-container {
  text-align: center;
}

.guidance {
  margin-right: 20px;
  margin-left: 20px;
  p {
    font-size: 22px;
    font-weight: 200;
    &.funeral-wishes-statement {
      white-space: pre-wrap;
      word-break: break-all;
      font-size: 14px;
    }
  }
  ul {
    li {
      font-size: 16px;
      margin-bottom: 8px;
    }
  }
  input[type='radio'],
  input[type='checkbox'] {
    margin-top: 5px;
  }
  label {
    font-size: $font-size-large;
    line-height: $font-size-large + $font-size-nudge;
  }
  textarea {
    width: 100%;
  }
  &.m7b {
    margin-bottom: 72px;
  }
}

// TODO: this should be moved to css/styleguide/
table {
  width: 100%;
  text-align: center;
  th {
    @if (lightness($brand-secondary) > 40) {
      color: $brand-text-primary-color;
    } @else {
      color: white;
    }
    background-color: lighten($brand-secondary, 8%);
    padding: 4px;
    font-size: 24px;
    font-weight: 300;
  }
  td {
    @if (lightness($brand-secondary) <= 40) {
      color: white;
    }
    padding: 4px;
  }
  tr:nth-child(odd) {
    background-color: lighten($brand-secondary, 28%);
  }
  tr:nth-child(even) {
    background-color: lighten($brand-secondary, 24%);
  }
  .summary-table {
    .subject {
      font-size: $font-size-large;
    }
    .content,
    .content p {
      text-align: left;
      font-size: $font-size;
    }
    .content td {
      border: 1px solid $brand-secondary;
    }
  }
}

.assets-list,
.relationships-list {
  .card {
    .fa {
      background-color: $brand-icon-primary-color;
      color: lighten($brand-icon-primary-color, $contrast-low);
      &.fa-plus {
        color: $brand-icon-primary-color;
        background: none;
      }
    }
    &:hover,
    &:focus {
      .fa {
        color: $brand-icon-primary-color;
        background-color: lighten($brand-icon-primary-color, $contrast-low);
        &.fa-plus {
          color: lighten($brand-icon-primary-color, $contrast-low);
          background: none;
        }
      }
    }
  }
}
