.twitter-typeahead {
  width: 100%;
}

.twitter-typeahead .tt-query,
.twitter-typeahead .tt-hint {
  margin-bottom: 0;
  min-height: 30px;
  width: 100%;
}

.tt-hint {
  background-color: white();
  border: 1px solid $brand-gray;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px black(0.075);
  color: #999;
  display: block;
  font-size: 14px;
  height: 38px;
  line-height: 1.428571429;
  padding: 8px 12px;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  vertical-align: middle;
  width: 100%;
}

.tt-dropdown-menu {
  background-clip: padding;
  background-clip: padding-box;
  background-color: white();
  border: 1px solid $brand-gray;
  border: 1px solid black(0.2);
  border-radius: 4px;
  box-shadow: 0 5px 10px black(0.2);
  display: inline-block;
  margin-top: 2px;
  max-height: 250px;
  min-width: 160px;
  overflow: auto;
  padding: 5px 0;
  position: relative;
  width: 100%;
}

.tt-suggestion {
  display: block;
  padding: 3px 20px;

  &:hover {
    background-color: $brand-dark-gray;
  }
}

.tt-suggestion.tt-is-under-cursor {
  background-color: $brand-text-highlight-color;
  background-image: -webkit-gradient(linear,
  0 0,
  0 100%,
  from(#08c),
  to(#0077b3));
  background-image: linear-gradient(top, #08c, #0077b3);
  background-image: linear-gradient(to bottom, #08c, #0077b3);
  background-repeat: repeat-x;
  color: $brand-text-highlight-color;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff0088cc', endColorstr='#ff0077b3', GradientType=0);

  a {
    color: white();
  }

  p {
    margin: 0;
  }
}
