@use 'sass:color';

.settings-wrapper .nav-wrapper {
  left: -25px;
  position: relative;
}

.social-indicator {
  border-radius: 10px;
  border-style: solid;
  border-width: 3px;
  display: block;
  font-size: 27px;
  height: 50px;
  margin: 5px;
  padding-top: 5px;
  text-align: center;
  width: 50px;

  &.facebook-indicator {
    background-color: color.adjust($facebook-blue, $lightness: -$contrast-low);
    border-color: $facebook-blue;
    color: $facebook-blue;
  }

  &.google-indicator {
    background-color: color.adjust($google-peach, $lightness: -$contrast-low);
    border-color: $google-peach;
  }

  &.desaturate {
    background-color: color.adjust($brand-button-disabled-color,
    $lightness: -$contrast-low);
    border-color: $brand-button-disabled-color;
    filter: grayscale(100%);
  }
}
