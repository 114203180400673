.wishes-section {
  min-height: 100px;
  padding-top: $grid-gutter-width * 0.5;
  padding-bottom: $grid-gutter-width * 0.5;

  .section-name,
  .section-text {
    padding-left: $grid-gutter-width * 0.5;

    @media screen and (min-width: $screen-md-min) {
      padding-left: 0;
    }
  }
  .section-name {
    color: $brand-text-primary-color;
    font-size: $font-size-large;
  }
  .section-text {
    color: $brand-text-primary-color;
    font-size: $font-size;
    display: inline-block;
  }

  .wishes-button {
    font-size: $font-size;
    width: 175px;
    max-width: 100%;
    height: 34px;
    margin-top: 15px;
    padding-right: 10px;
    padding-left: 10px;

    &.wishes-button-guardians {
      margin-top: 8px;

      @media screen and (min-width: $screen-md-min) {
        margin-top: 0;
      }
    }
  }

  .fa-stack {
    vertical-align: top;
    color: $brand-primary;
    font-size: $font-size-large;
    &.completed {
      color: $brand-icon-complete-color;
    }
    .fa-arrow-right {
      margin-top: -1px;
    }
  }
}
