@use 'sass:color';

.alert {
  .btn {
    line-height: 1.7em;
  }
}

.will-summary {
  .progress {
    margin-left: 0;
    margin-right: 0;

    .progress-col {
      width: 12.5%;
    }
  }
}

.review-wrapper {
  @extend .form-wrapper;

  text-align: center;

  input[type='radio'] {
    margin: 0;
    opacity: 0;
    padding: 0;
    width: 0;

    &:checked {
      background: $brand-primary;
    }
  }
}

.clause-wrapper {
  color: color.adjust($brand-text-primary-color, $lightness: -$contrast);
  font-size: 16px;
  font-weight: 300;
  padding: 10px;
  text-align: left;
}

.summaryHeader {
  font-size: 24px;
}

.summarySubHeader {
  font-size: 22px;
  margin-top: 10px;
}

.warning {
  color: color.adjust($brand-text-highlight-color, $lightness: -$contrast);
  font-size: 24px;
}

.fa-question-circle {
  color: $brand-secondary;
  font-size: 20px;
}

.fa-exclamation-circle {
  color: color.adjust($brand-icon-highlight-color, $lightness: -$contrast);
  font-size: 20px;
}

.centered {
  text-align: center;
}

.approved,
.approved:focus,
.approved:hover {
  background: $brand-icon-complete-color;
}

.unapproved,
.unapproved:focus {
  background: $brand-primary;
}

.unapproved,
.unapproved:focus {
  background: $brand-primary;
}

.will-menu {
  .will-menu-section {
    font-size: $font-size-large - $font-size-nudge;
    list-style-type: none;
    margin-bottom: 5px;
    margin-top: 5px;

    a {
      color: $brand-primary;

      &.done {
        color: $brand-icon-complete-color;
      }
    }

    span {
      text-align: left;
    }

    .will-menu-text {
      padding-top: 5px;
      text-align: center;
    }

    .will-menu-icon {
      padding-left: 0;
      padding-right: 0;
    }
  }

  @media (min-width: $screen-md-min) {
    margin-bottom: 5px;
  }
}
