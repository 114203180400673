@use 'sass:color';

.menu {
  background: $brand-menu-background-color;
  height: 100%;
  position: relative;

  .menu__top {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 25px;
  }

  .menu__bottom {
    background-color: color.adjust($brand-border-primary-color,
    $lightness: $contrast);
    border-top: 1px solid
      color.adjust($brand-border-primary-color, $lightness: $contrast-low);
    min-height: 15%;
    padding-bottom: $grid-gutter-width;

    @media screen and (height <= 468px) {
      padding-top: $grid-gutter-width * 0.5;
    }

    @media screen and (height >= 700px) {
      padding-top: $grid-gutter-width * 0.5;
    }
  }

  .menu__top,
  .menu__bottom {
    .menu__text,
    .menu__link {
      font-size: $font-size + $font-size-nudge;
      height: 30px;
      line-height: 30px;
      padding: 4px 8px;
      text-align: center;

      &:not(:last-child) {
        &::after {
          color: color.adjust($brand-border-primary-color,
          $lightness: $contrast-low);
          content: '|';
          display: block;
          position: absolute;
          right: 1px;
          top: 4px;
        }
      }
    }
  }

  .menu__body {
    height: 80%;
    overflow: hidden;
    position: relative;
  }

  .menu__logo {
    background: url('../img/furniture/logo-nav.png') left top no-repeat;
    background-position: left center;
    background-size: 99px 40px;
    display: block;
    height: 45px;
    min-width: 128px;
    overflow: hidden;
    padding-left: 0;
    padding-right: 0;
    text-indent: -1000px;
  }

  .menu__text,
  .menu__link,
  .menu__toggle {
    color: $brand-menu-link-color;
    overflow: hidden;
    text-decoration: none;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .menu__link,
  .menu__toggle {
    &:focus {
      color: $brand-menu-link-color;
    }

    &:hover {
      color: color.adjust($brand-menu-link-color, $lightness: $contrast);
    }
  }

  .menu__link {
    transition: padding 0.3s ease-in-out;

    &:not([href]) {
      color: $brand-menu-link-disabled-color;
    }
  }

  .menu__toggle {
    background: none;
    border: none;
    border-radius: 0 3px 3px 0;
    cursor: pointer;
    font-size: $font-size-small + $font-size-nudge;
    height: 50px;
    margin-top: -5px;
    padding: 0 3px;
    text-align: center;
  }

  .menu__list {
    font-size: $font-size + $font-size-nudge;
    font-weight: 200;
    padding: 0;
  }

  .menu__list__item {
    line-height: 56px;
    transition: box-shadow 0.3s ease-in-out;

    .fa {
      &.fa-arrow-right {
        // px-nudge
        top: -1px;
      }
    }

    .done {
      color: $brand-menu-link-complete-color;
    }

    &.active {
      border-radius: 8px;
      box-shadow: 2px 2px 12px $brand-box-shadow-color;

      .menu__link {
        padding-left: 5px;
      }
    }

    &:focus {
      .menu__link {
        // deprecated
        color: $brand-primary;
      }

      .done {
        // deprecated
        color: $brand-green;
      }
    }

    &:hover {
      .menu__link {
        color: $brand-menu-link-color;
      }

      .done {
        color: $brand-menu-link-complete-color;
      }
    }
  }

  .menu__primary {
    position: absolute;
    width: 100%;
  }

  .menu__primary {
    .menu__list {
      margin-top: $grid-gutter-width;
    }
  }
}
