@use 'sass:math';

/**
 *  transition() mixin
 *  short-hand transitions
 */
@mixin transition($transition...) {
  transition: $transition;
}

/**
 *  animateDelay() mixin
 *  animation speed defaults to global setting
 *  usage:
 *      e.g. animation of block
 *      .block {
 *          @include animateDelay();
 *      }
 *
 *      e.g. sequence animation of blocks
 *      .first-block {
 *          @include animateDelay(0);
 *      }
 *      .second-block {
 *          @include animateDelay(1);
 *      }
 *      .third-block {
 *          @include animateDelay(2);
 *      }
 *
 */
@mixin animateDelay($delay: 0, $speed: $animation-speed) {
  @extend %animated;

  animation-delay: $delay * math.div($speed, 3);
  animation-duration: $speed;
}

/**
 *  animateIn() mixin
 *  sequenced entrance animation
 *  for usage, see animateDelay()
 */
@mixin animateIn($sequence: 0) {
  @extend %zoomIn;
  @include animateDelay($sequence);
}

/**
 *  animateInTop() mixin
 *  sequenced entrance animation from top
 *  for usage, see animateIn()
 */
@mixin animateInTop($sequence: 0) {
  @extend %fadeInDown;
  @include animateDelay($sequence);
}

/**
 *  animateInRight() mixin
 *  sequenced entrance animation from right
 *  for usage, see animateIn()
 */
@mixin animateInRight($sequence: 0) {
  @extend %fadeInRight;
  @include animateDelay($sequence);
}

/**
 *  animateInBottom() mixin
 *  sequenced entrance animation from bottom
 *  for usage, see animateIn()
 */
@mixin animateInBottom($sequence: 0) {
  @extend %fadeInUp;
  @include animateDelay($sequence);
}

/**
 *  animateInLeft() mixin
 *  sequenced entrance animation from left
 *  for usage, see animateIn()
 */
@mixin animateInLeft($sequence: 0) {
  @extend %fadeInLeft;
  @include animateDelay($sequence);
}
