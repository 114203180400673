@use 'sass:color';

.card {
  --fa-border-radius: 3px 3px 0 0;
  --fa-border-color: transparent;
  --fa-border-padding: none;

  background: white;
  border-radius: 4px;
  box-shadow: 0 1px 1px
    color.adjust($brand-card-box-shadow-color, $lightness: -$contrast);
  color: white;
  display: inline-block;
  font-weight: 400;
  height: 150px;
  margin: 5px;
  padding: 0;
  position: relative;
  vertical-align: top;
  width: 100px;

  .img-container {
    background-color: $brand-icon-primary-color;
    border-radius: 4px 4px 0 0;
    color: color.adjust($brand-icon-primary-color, $lightness: $contrast-low);
    height: 90px;
    overflow: hidden;
    width: 100%;

    img {
      margin: 0;
      width: 100%;
    }
  }

  .label {
    color: color.adjust($brand-text-primary-color, $lightness: -$contrast);
    font-size: 14px;
    margin: 2px;
    padding: 0;
    text-align: left;
    text-transform: capitalize;
  }

  .label-percent {
    bottom: 2px;
    color: color.adjust($brand-text-highlight-color, $lightness: -$contrast);
    font-size: 20pt;
    font-weight: 300;
    position: absolute;
    right: 5px;
  }

  .fa {
    font-size: 80px;
    text-align: center;
    width: 100%;
  }

  .edit,
  .remove {
    background: white(0.5); // no variable because it's a white overlay
    color: white;
    cursor: pointer;
    display: none;
    font-size: 20px;
    height: 100%;
    left: 0;
    padding-top: 60px;
    position: absolute;
    text-align: center;
    text-shadow: 0 2px 2px $brand-card-box-shadow-color;
    text-transform: uppercase;
    top: 0;
    width: 100%;

    .fa {
      color: color.adjust($brand-text-highlight-color, $lightness: -$contrast);
      font-size: 24px;
      position: relative;
      top: 10px;
    }
  }

  &:hover {
    .edit,
    .remove {
      display: block;
    }
  }

  &.drop-zone {
    background-color: white;
    border: dashed 2px
      color.adjust($brand-card-box-shadow-color, $lightness: -$contrast);
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    min-height: 150px;
    text-align: center;
    text-decoration: none;

    .text {
      color: $brand-text-primary-color;
      font-size: $font-size-large;
    }

    .fa {
      font-size: 60px;
    }
  }
}

.details {
  .lead {
    font-size: $font-size-large + $font-size-nudge * 2;
  }

  .control-label {
    font-size: $font-size-large;
    margin-top: -4px;

    &::after {
      content: ':';
    }
  }

  .card-nav {
    margin: $grid-gutter-width * 0.5 auto;
    text-align: center;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }

    .nav-pills {
      display: inline-block;
      overflow: hidden;

      li {
        font-size: 18px;
      }
    }
  }
}

.disconnected-wrapper {
  @include forceGPU;
  @include animateInBottom(2);

  background: $brand-page-background-color;
  border-radius: 0 0 10px 10px;
  bottom: 0;
  box-shadow: inset 0 1px 0 $brand-box-shadow-color;
  left: 0;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  white-space: nowrap;
  width: 100%;
  z-index: 2;

  .disconnected-list-header {
    background-color: $brand-secondary;
    color: color.adjust($brand-secondary, $lightness: $contrast-high);
    font-size: 22px;
    padding-bottom: 5px;
    padding-left: 15px;
    padding-top: 5px;
  }

  .disconnected-list {
    min-height: 133px;
    text-align: center;

    .card {
      border-radius: 0;
      box-shadow: none;
      height: 90px;
      margin: 20px 10px;
      text-align: left;
      width: 200px;
    }

    .btn-tertiary {
      height: 55px;
      margin-top: 36px;
      padding: 15px;
      position: absolute;

      &.btn-left {
        left: 22px;
      }

      &.btn-right {
        right: 22px;
      }
    }
  }
}

//
// Family Tree
//
.ft-line {
  stroke: black;
}

.ft-card-background {
  fill: #c2ce94;
}

.ft-card-firstname,
.ft-card-lastname {
  fill: color.adjust($brand-secondary, $lightness: $contrast);
  font-family: Raleway, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 22px;
  font-weight: 400;
}

.ft-card-edit {
  cursor: pointer;
}

.ft-card-edit-background {
  fill: color.adjust($brand-secondary, $lightness: $contrast);
}

.ft-card-edit-text {
  fill: color.adjust($brand-secondary, $lightness: -$contrast-high);
  font-family: Raleway, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 300;
  text-transform: uppercase;
}

.ft-scale {
  background-color: white(0);
  border-radius: 2px;
  left: 28px;
  position: absolute;
  text-align: center;
  top: 20px;
  width: 40px;
  z-index: 1;
}

.ft-scale-range {
  appearance: slider-vertical; /* WebKit */
  height: 175px;
  left: 13px;
  padding: 0 5px;
  width: 8px;
  writing-mode: bt-lr; /* IE */
}
