.carousel {
  display: flex;
  gap: 5px;
  position: relative;
}

.carousel-track {
  display: flex;
  flex: 1;
  gap: 5px;
  list-style: none;
  margin-bottom: 10px;
  margin-top: 10px;
  max-height: 100%;
  order: 2;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
}

.carousel-track > * {
  place-content: center;
  display: grid;
  flex-shrink: 0;
  scroll-snap-align: start;
}

.carousel-nav {
  @extend .btn;

  align-self: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 34px;
  padding: 49px 0;
}

.carousel-nav > .vertical {
  display: none;
}

.carousel-nav-left {
  order: 1;
}

.carousel-nav-right {
  order: 3;
}

@media screen and (min-width: $screen-sm-min) {
  .carousel {
    flex-direction: column;
    height: calc(100% - 20px);
    margin: 10px 0;
  }

  .carousel-track {
    flex-direction: column;
    overflow: none auto;
    scroll-snap-type: y mandatory;
  }

  .carousel-nav {
    width: auto;
    padding: 0 30px;
  }

  .carousel-nav > .horizontal {
    display: none;
  }

  .carousel-nav > .vertical {
    display: inline;
  }
}
