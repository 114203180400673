@use 'sass:math';

// Box sizing
*,
*,
*::after {
  box-sizing: border-box;
}

// Viewport sizing
body {
  background: $brand-body-background-color;
  margin: 0;
  padding: 0;
}

// Responsive images
img {
  @include img-responsive;
}

// View displays as block
ng-view {
  display: block;
}

// Scope containers always relative
.ng-scope,
.relative {
  position: relative;
}

// Ignore injected script tags
script.ng-scope {
  position: absolute;
}

// Templates hide without loaded scripts
.ng-hide {
  display: none;
}

// Makes row-xs-1 (height: 5%) to row-xs-20 (height: 100%)
@for $i from 20 through 1 {
  .row-xs-#{$i} {
    height: $i * 5%;
  }
}

// Layout wrappers
.wrapper {
  // $container-ultra-wide-desktop
  @include container-fixed;

  @media screen and (min-width: $screen-uw-min) {
    width: $container-uw;
  }

  padding: 0;

  // &.wrapper--nav-expanded {}
  // &.wrapper--view-expanded {}
}

.wrapper__loading,
.wrapper__menu,
.wrapper__content {
  @include forceGPU;

  bottom: 0;
  position: fixed;
  top: 0;
}

.wrapper__loading,
.wrapper__content {
  overflow: visible;

  @media screen and (min-width: $screen-md-min) {
    bottom: $grid-gutter-width;
    top: $grid-gutter-width;
  }

  @media screen and (height <= 468px) {
    min-height: 469px;
    position: relative;
  }
}

.wrapper__loading {
  color: $brand-spinner-color;
  left: 0;
  right: 0;
  text-align: center;
  vertical-align: middle;

  i {
    $icon-size-xs: 128px;
    $icon-size-md: 192px;

    font-size: $icon-size-xs;
    height: $icon-size-xs;
    left: 50%;
    line-height: $icon-size-xs;
    margin-left: math.div($icon-size-xs, -2);
    margin-top: math.div($icon-size-xs, -2);
    opacity: 0.5;
    position: absolute;
    top: 25%;
    width: $icon-size-xs;

    @media screen and (min-width: $screen-md-min) {
      font-size: $icon-size-md;
      height: $icon-size-md;
      line-height: $icon-size-md;
      margin-left: math.div($icon-size-md, -2);
      margin-top: math.div($icon-size-md, -2);
      top: 35%;
      width: $icon-size-md;
    }
  }
}

.wrapper__menu {
  background: none;
  height: auto;
  margin: 0;
  min-width: $layout-menu-min-width;
  right: $layout-menu-offset-xs;
  width: $layout-menu-width-xs;

  @media screen and (min-width: $screen-sm-min) {
    right: $layout-menu-offset-sm;
    width: $layout-menu-width-sm;
  }

  @media screen and (min-width: $screen-md-min) {
    right: $layout-menu-offset-md;
    width: $layout-menu-width-md;
  }

  @media screen and (min-width: $screen-lg-min) {
    right: $layout-menu-offset-lg;
    width: $layout-menu-width-lg;
  }

  @media screen and (height <= 468px) {
    height: 525px;
    position: absolute;

    .menu__bottom {
      @media screen and (min-width: $screen-md-min) {
        border-radius: 0 0 10px;
      }
    }
  }

  .wrapper--nav-offset & {
    right: $layout--nav-offset-menu-offset-xs;

    @media screen and (min-width: $screen-md-min) {
      right: $layout--nav-offset-menu-offset-md;
    }
  }

  .wrapper--nav-expanded & {
    right: $layout-expanded-offset-xs;
    width: $layout-expanded-width-xs;

    @media screen and (min-width: $screen-md-min) {
      right: $layout-expanded-offset-md;
      width: $layout-expanded-width-md;
    }
  }

  .wrapper--view-expanded & {
    right: $layout-retracted-offset;
  }
}

.wrapper__content {
  display: flex;
  flex-direction: column;
  left: $layout-content-offset-xs;
  transition: all 0.3s ease-in-out;
  width: $layout-content-width-xs;

  @media screen and (min-width: $screen-sm-min) {
    left: $layout-content-offset-sm;
    width: $layout-content-width-sm;
  }

  @media screen and (min-width: $screen-md-min) {
    left: $layout-content-offset-md;
    width: $layout-content-width-md;
  }

  @media screen and (min-width: $screen-lg-min) {
    left: $layout-content-offset-lg;
    width: $layout-content-width-lg;
  }

  .wrapper--no-layout-script & {
    position: relative;
  }

  .wrapper--nav-offset & {
    left: $layout--nav-offset-content-offset-xs;
    width: $layout--nav-offset-content-width-xs;

    @media screen and (min-width: $screen-md-min) {
      left: $layout--nav-offset-content-offset-md;
      width: $layout--nav-offset-content-width-md;
    }
  }

  .wrapper--nav-expanded & {
    left: $layout-retracted-offset;
  }

  .wrapper--view-expanded & {
    left: $layout-expanded-offset-xs;
    width: $layout-expanded-width-xs;

    @media screen and (min-width: $screen-md-min) {
      left: $layout-expanded-offset-md;
      width: $layout-expanded-width-md;
    }
  }
}

.wrapper__advice {
  @include container-fixed;

  background-color: $brand-page-background-color;
  border: 1px solid $brand-secondary;
  box-shadow: 2px 2px 12px $brand-box-shadow-color;
  margin-left: inherit;
  margin-right: inherit;

  @media screen and (min-width: $screen-md-min) {
    border-radius: 10px;
    margin-bottom: 10px;
  }
}

.wrapper__view {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow: hidden;

  > .ng-scope:not(.wrapper__page, .wrapper__controls) {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow: hidden;
  }
}

.wrapper__page {
  @include container-fixed;

  background-color: $brand-page-background-color;
  border: 1px solid $brand-secondary;
  border-bottom: none;
  box-shadow: 2px 2px 12px $brand-box-shadow-color;
  flex-grow: 1;
  margin-left: inherit;
  margin-right: inherit;
  min-height: 300px;
  overflow: auto;

  &.no-controls {
    display: flex;
    flex-direction: column;
  }

  @media screen and (min-width: $screen-md-min) {
    border-radius: 10px 10px 0 0;

    &.no-controls {
      border-radius: 10px;
      margin-bottom: 10px;
    }
  }
}

.wrapper__controls {
  @include container-fixed;

  background-color: $brand-page-background-color;
  border: 1px solid $brand-secondary;
  border-top: none;
  box-shadow: 2px 2px 12px $brand-box-shadow-color;
  margin-bottom: 0;
  margin-left: inherit;
  margin-right: inherit;
  position: relative;
  z-index: 1;

  p,
  .btn,
  button {
    display: block;
    margin: $grid-gutter-width * 0.5 auto;
    text-align: center;
  }

  @media screen and (min-width: $screen-md-min) {
    border-radius: 0 0 10px 10px;
    margin-bottom: 10px;
    max-height: 60px;

    p,
    .btn,
    button {
      display: inline-block;
      margin: 10px auto;
      text-align: inherit;
    }
  }
}

// image backdrop
.backdrop {
  @media screen and (min-width: $screen-sm-min) {
    &::before {
      @include forceGPU;

      background: url('../img/furniture/backdrop.jpg') center no-repeat;

      // scale background size to fill the screen
      background-size: cover;
      inset: 0;
      content: '';
      position: fixed;
      z-index: -1;
    }
  }
}

.list-unstyled {
  list-style: none;
  padding-left: 0;
}

.toggle-xs {
  display: none;
}

// Retrofit mobile view experiment
@media screen and (max-width: $screen-sm-min) {
  .toggle-xs {
    display: inline;
  }

  .toggle-md {
    display: none;
  }

  .wrapper__loading,
  .wrapper__menu,
  .wrapper__content {
    bottom: initial;
    position: initial;
    top: initial;
  }

  .wrapper__menu {
    background: none;
    height: initial;
    margin: 0;
    min-width: initial;
    right: initial;
    transition: all 0.3s ease-in-out;
    width: initial;

    .wrapper--view-expanded & {
      right: initial;
    }
  }

  .wrapper__content {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 45px);
    left: initial;
    transition: all 0.3s ease-in-out;
    width: initial;

    .wrapper--nav-offset & {
      display: none;
      left: initial;
      width: initial;
    }

    .wrapper--nav-expanded & {
      display: none;
      left: initial;
    }

    .wrapper--view-expanded & {
      left: initial;
      width: initial;
    }
  }

  // bleeding into _menu.scss
  .wrapper__menu {
    .menu {
      display: flex;
      flex-direction: column;

      .menu__top,
      .menu__body,
      .menu__bottom {
        align-items: center;
        display: flex;
        justify-content: space-around;
        margin-top: 0 !important;
      }

      .menu__body,
      .menu__bottom {
        display: none;
      }

      .menu__top {
        .menu__logo {
          min-width: 128px;
        }

        .freemium-label {
          margin-bottom: initial;
          margin-top: initial;
        }
      }

      .menu__body {
        align-items: flex-start;
        flex-grow: 1;
        height: inherit;

        .menu__primary {
          margin-right: 1em;
          position: relative;
        }
      }

      .wrapper--nav-offset & {
        height: 100vh;

        .menu__body,
        .menu__bottom {
          display: flex;
        }
      }
    }
  }
}
