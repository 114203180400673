.title-bar {
  position: relative;

  .logo,
  .title {
    display: inline-block;
    float: none;
    vertical-align: baseline;
  }

  .logo {
    // constraints from menu logo
    height: auto;
    max-height: 100%;
    max-width: 99px;
  }

  .title {
    font-size: $font-size-jumbo;
    line-height: 1;
  }
}
