// Uploadcare styling

// The container for the buttons and text below the image
.uploadcare-widget-status,
.uploadcare-widget-status-loaded,
.uploadcare-widget-status-started {
  display: block;
  white-space: normal;
}

// The 'update' link that re-opens the Uploadcare UI
// .uploadcare-widget-file-name { }
// The text that appears next to the link
.image-upload-container {
  position: relative;

  .uploadcare-widget {
    max-width: 100%;
  }

  .uploadcare-widget-text {
    display: none !important;
  }

  // The image's container styling
  img {
    border-radius: 10px;
    display: inline-block;
    height: auto;
    margin: 5px auto;
    max-width: 100%;
    vertical-align: middle;
    width: 140px;
  }

  // Various buttons
  .uploadcare-widget-button,
  .uploadcare-normal-button {
    @extend .btn;
    @extend .btn-small;

    margin: 5px auto !important;
    max-width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    text-transform: none !important;
    width: 140px !important;
  }

  .uploadcare-widget-button-remove,
  .uploadcare-widget-button-cancel {
    @extend .btn, .btn-warning;
  }
}
