/* Adds colon to the end of the content */
@mixin trailingColon() {
  &::after {
    content: ':';
  }
}

/* Adds some space to the end of the conent */
@mixin trailingTab() {
  &::after {
    content: '\00A0\00A0';
  }
}

/* Adds some space to the start of the conent */
@mixin leadingTab() {
  &::before {
    content: '\00A0\00A0';
  }
}
