@use 'sass:color';

/**
 *  animateInText() mixin
 *  sequenced entrance animation for text
 *  for usage, see animateDelay()
 */
@mixin animateInText($sequence: 0) {
  @include animateDelay($sequence, $animation-speed-gentle);

  animation-name: neonGlowBlue;
}

/**
 *  Neon glow colors animation
 */
@keyframes neonGlowBlue {
  // from
  100% {
    text-shadow: none;
  }

  // blue
  66% {
    text-shadow:
      0 0 16px color.mix(white, $brand-primary, 100%),
      0 0 14px color.mix(white, $brand-primary, 85.7%),
      0 0 12px color.mix(white, $brand-primary, 71.4%),
      0 0 10px color.mix(white, $brand-primary, 57.1%),
      0 0 8px color.mix(white, $brand-primary, 42.8%),
      0 0 6px color.mix(white, $brand-primary, 28.6%),
      0 0 4px color.mix(white, $brand-primary, 14.3%);
  }

  // to
  0% {
    text-shadow: none;
  }
}
