@use 'sass:color';

.advice {
  font-size: $font-size-large;
  text-decoration: none;

  .advice__text,
  .advice__lead {
    @include animateInText(0);
  }

  .advice__lead {
    font-size: $font-size-large + $font-size-nudge * 2;
  }

  .advice__link {
    @include animateInText(1);

    color: $brand-primary;

    &:focus {
      color: $brand-primary;
    }

    &:hover {
      color: color.adjust($brand-primary, $lightness: $contrast);
    }
  }
}

.fixed-page-overlay {
  @include forceGPU;

  background: black(0.33);
  height: 100%;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 99998;
}

.fixed-top {
  @include forceGPU;

  color: $brand-white;
  left: 0;
  position: fixed;
  right: 0;
  text-shadow: none;
  top: 0;
  z-index: 99999;

  .message {
    background: rgba($brand-black, 0.9);
    margin-bottom: 1px;
    overflow: hidden;

    > * {
      padding: 10px;
    }

    &:hover .fa-times {
      color: #b7a040;
    }
  }
}

.well {
  background-color: color.adjust($brand-secondary, $lightness: $contrast);
  margin-top: 12px;
  min-height: 24px;
  padding: 4px 16px 16px;
  text-align: left;

  .fa {
    font-size: $font-size-large;
    vertical-align: baseline;
  }
}
