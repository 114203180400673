@charset "UTF-8";

// libraries directory,
// but variables in @import not currently supported in SASS:
// https://github.com/sass/sass/issues/279
// $lib: "../../node_modules/"

// variables
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import 'variables';

// mixins
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/component-animations';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities';
@import 'mixins/browser';
@import 'mixins/color';
@import 'mixins/leading';

// icons
$fa-font-path: '../../node_modules/@fortawesome/fontawesome-free/webfonts';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/fontawesome';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/solid';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/brands';
@import '../../node_modules/@fortawesome/fontawesome-free/scss/v4-shims';

//animations
@import 'animations/animate'; // also contains mixins
// @import "animations/loading";
@import 'animations/menu';
@import 'animations/messaging';

// styleguide
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/type';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/forms';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/buttons';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/button-groups';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/input-groups';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/media';
@import 'styleguide/layout';
@import 'styleguide/typography';
@import 'styleguide/buttons';
@import 'styleguide/cards';
@import 'styleguide/inputs';
@import 'styleguide/menu';
@import 'styleguide/titles';
@import 'styleguide/messaging';
@import 'styleguide/progress';
@import 'styleguide/tabs';
@import 'styleguide/carousel';
@import 'styleguide/typeahead';
@import 'styleguide/images';

// components
@import 'components/account';
@import 'components/documents';
@import 'components/frontpage';
@import 'components/relationships';
@import 'components/review';
// wishes components
@import 'components/wishes/main';
@import 'components/wishes/creator';
@import 'components/wishes/layout';
@import 'components/wishes/wrappers';

// helpers
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/grid';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-embed';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/utilities';
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities';
@import 'helpers';

// print
@import '../../node_modules/bootstrap-sass/assets/stylesheets/bootstrap/print';
