@use 'sass:color';
@use 'sass:list';
@use 'sass:math';

// Interface Luminosity Ratio
// contrast between normal, light, and dark colors
$luminosity: 0.2;
$luminosity-low: 0.1;
$luminosity-high: 0.4;
// luminosity converted to %
$contrast: $luminosity * 100%;
$contrast-low: $luminosity-low * 100%;
$contrast-high: $luminosity-high * 100%;

// Brand Colors (or variables)
// ----- Change these -----
$brand-white: #fefefd;
$brand-black: #434a54;

$brand-gray: #e6e6e6;
$brand-dark-gray: color.adjust($brand-gray, $lightness: -$contrast);
$brand-blue: #166969;
$brand-green: #99ad4f;
$brand-dark-green: #597b57;
$brand-light-green: color.adjust($brand-green, $lightness: $contrast);
$brand-orange: #ba9739;
$brand-light-orange: color.adjust($brand-orange, $lightness: $contrast);
$brand-brown: color.adjust($brand-orange, $lightness: -$contrast);
$brand-red: #ca532f;
$brand-dark-red: color.adjust($brand-red, $lightness: -$contrast-low);

// Brand colors
$brand-primary: $brand-blue;
$brand-secondary: color.adjust($brand-green, $lightness: $contrast);
$brand-highlight: $brand-red;

// Layout Colors
$brand-body-background-color: $brand-gray;
$brand-menu-background-color: $brand-white;
$brand-page-background-color: $brand-white;

$brand-border-primary-color: $brand-secondary;
$brand-border-secondary-color: $brand-gray;

$brand-asset-color: $brand-green;

// Card colors
$brand-card-box-shadow-color: $brand-gray;

// Icon colors
$brand-icon-primary-color: $brand-primary;
$brand-icon-complete-color: $brand-green;
$brand-icon-highlight-color: $brand-red;
$brand-icon-disabled-color: color.adjust(
  $brand-gray,
  $lightness: -$contrast-low
);

$brand-card-primary-color: $brand-secondary;

$brand-spinner-color: $brand-white;

$brand-nav-pill-background-color: $brand-gray;

$brand-scrollbar-color: color.adjust($brand-green, $lightness: -$contrast);

// Brand form colors
$brand-form-background-color: $brand-white;
$brand-form-border-color: $brand-gray;
$brand-form-invalid-color: $brand-red;

$brand-box-shadow-color: color.adjust($brand-gray, $lightness: -$contrast-high);

// Brand text colors
$brand-text-primary-color: color.adjust(
  $brand-gray,
  $lightness: -$contrast-high
);
$brand-text-highlight-color: $brand-highlight;
$brand-text-shadow-color: $brand-gray;

// Brand link colors
$brand-link-primary-color: $brand-secondary;
$brand-link-highlight-color: $brand-red;
$brand-link-disabled-color: color.adjust($brand-gray, $lightness: -$contrast);

// Brand menu colors
$brand-menu-link-color: $brand-primary;
$brand-menu-link-disabled-color: $brand-link-disabled-color;
$brand-menu-link-complete-color: $brand-icon-complete-color;

// Brand button colors
$brand-button-normal-color: $brand-secondary;
$brand-button-continue-color: $brand-primary;
$brand-button-highlight-color: $brand-red;
$brand-button-disabled-color: $brand-gray;

// Typography
$line-height: 1.428571429; // 20/14
$font-weight: normal;
$font-weight-light: 200;
$font-size: 14px;
$font-size-small: floor($font-size * 0.85);
$font-size-large: ceil($font-size * $line-height);
$font-size-jumbo: ceil($font-size-large * ($line-height * $line-height));
$font-size-nudge: 2px; // size of increment steps
$font-family: 'Raleway', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$font-stack: $font-weight list.slash($font-size, 1.2) $font-family;

// Headings
$font-headings: (
  (h1, floor($font-size * 2.6)),
  (h2, floor($font-size * 2.15)),
  (h3, ceil($font-size * 1.7)),
  (h4, ceil($font-size * 1.25)),
  (h5, $font-size),
  (h6, $font-size-small)
);

// Animation
$animation-speed: 0.66s;
$animation-speed-quick: math.div($animation-speed, 3);
$animation-speed-gentle: $animation-speed * 3;

// Social Colors
$facebook-blue: #3b5998;
$google-peach: #dd4b39;

// Ultra-Wide Screens
$screen-uw-min: ($screen-lg-min * 1.5);
$screen-lg-max: ($screen-uw-min - 1);
$container-ultra-wide-desktop: (
  ($screen-uw-min - ($grid-gutter-width * 2)) + $grid-gutter-width
);
$container-uw: $container-ultra-wide-desktop;

// Layout Sizing
// TODO: add support for ultra-wide (uw)
$layout-buffer: 2%;
$layout-menu-min-width: 330px;
$layout-menu-width-xs: 48%;
$layout-menu-offset-xs: 100 - $layout-menu-width-xs;
$layout-menu-width-sm: 40%;
$layout-menu-offset-sm: 100 - $layout-menu-width-sm;
$layout-menu-width-md: 33%;
$layout-menu-offset-md: 100 - $layout-menu-width-md;
$layout-menu-width-lg: 26%;
$layout-menu-offset-lg: 100 - $layout-menu-width-lg;
$layout-content-offset-xs: $layout-menu-width-xs;
$layout-content-width-xs: 100 - $layout-content-offset-xs;
$layout-content-offset-sm: $layout-menu-width-sm;
$layout-content-width-sm: 100 - $layout-content-offset-sm;
$layout-content-offset-md: $layout-menu-width-md + $layout-buffer;
$layout-content-width-md: 100 - $layout-content-offset-md - $layout-buffer;
$layout-content-offset-lg: $layout-menu-width-lg + $layout-buffer;
$layout-content-width-lg: 100 - $layout-content-offset-lg - $layout-buffer;

$layout-menu-width-xs: 48%;
$layout-menu-offset-xs: 100 - $layout-menu-width-xs;
$layout-menu-width-md: 33%;
$layout-menu-offset-md: 100 - $layout-menu-width-md;
$layout-content-offset-xs: $layout-menu-width-xs;
$layout-content-width-xs: 100 - $layout-content-offset-xs;
$layout-content-offset-md: $layout-menu-width-md + $layout-buffer;
$layout-content-width-md: 100 - $layout-content-offset-md - $layout-buffer;

$layout--nav-offset-menu-offset-xs: 100 - ($layout-buffer * 2);
$layout--nav-offset-menu-offset-md: 100 - math.div($layout-buffer * 4, 3);
$layout--nav-offset-content-offset-xs: $layout-buffer * 2;
$layout--nav-offset-content-width-xs: 100 -
  $layout--nav-offset-content-offset-xs;
$layout--nav-offset-content-offset-md: math.div($layout-buffer * 4, 3) +
  $layout-buffer;
$layout--nav-offset-content-width-md: 100 -
  $layout--nav-offset-content-offset-md - $layout-buffer;

// --nav-expanded, --view-expanded variants
$layout-expanded-width-xs: 100%;
$layout-expanded-offset-xs: 100 - $layout-expanded-width-xs;
$layout-expanded-offset-md: $layout-buffer * 2;
$layout-expanded-width-md: 100 - ($layout-expanded-offset-md * 2);
$layout-retracted-offset: 100%;
