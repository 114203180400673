@use 'sass:color';

.nav-tabs {
  background-color: $brand-page-background-color;
  list-style: none;
  margin: 0;
  margin-bottom: -26px;
  margin-top: 50px;
  padding: 0;
  width: 100%;

  @media screen and (max-width: $screen-sm-max) {
    height: auto;
  }

  li {
    font-weight: 400;
    height: 35px;
    line-height: 35px;

    a {
      display: block;
      padding: 0 20px;
    }

    &.active {
      background: $brand-body-background-color;
      border-color: color.adjust($brand-body-background-color,
      $lightness: -$contrast);
      border-bottom-color: transparent;
    }
  }
}

.nav-pills {
  > li {
    float: left;
    font-weight: 400;
    height: 35px;
    line-height: 35px;

    // Links rendered as pills
    > a {
      border-radius: 3px;
      display: block;
      padding: 0 20px;
    }

    + li {
      margin-left: 2px;
    }

    // Active state
    &.active > a {
      &,
      &:hover,
      &:focus {
        background: $brand-nav-pill-background-color;
      }
    }
  }
}
