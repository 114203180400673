/**
 *  black() function
 *	shorthand color with opacity
 */
@function black($opacity: 1) {
  @return rgba(0, 0, 0, $opacity);
}

/**
 *  white() function
 *	shorthand color with opacity
 */
@function white($opacity: 1) {
  @return rgba(255, 255, 255, $opacity);
}
