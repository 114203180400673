@use 'sass:color';

.btn {
  @include noselect;

  background-color: $brand-button-normal-color;
  border: none;
  border-radius: 2px;
  box-shadow: 0 1px 0 0
    color.adjust($brand-button-normal-color, $lightness: -$contrast-low);
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: $font-size-large;
  font-weight: 400;
  height: 34px;
  line-height: 34px;
  max-width: 320px;
  outline: none;
  overflow: hidden;
  padding: 0 30px;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  text-transform: uppercase;
  vertical-align: middle;

  &:hover,
  &:focus,
  &:active {
    background-color: color.adjust($brand-button-normal-color,
    $lightness: -$contrast-low);
    color: white;
  }

  &:active {
    transform: translateY(1px);
  }

  &.btn-small {
    font-size: $font-size;
    height: 21px;
    line-height: 21px;
    padding: 0 20px;
  }

  &.btn-large {
    max-width: 400px;
  }

  &.btn-wide {
    min-width: 280px;
  }

  .fa {
    color: white;
    font-size: $font-size-large !important;
  }

  &.btn-link {
    background: none;
    border: none;
    border-radius: none;
    box-shadow: none;
    color: $brand-link-primary-color;
    font-size: $font-size-large;
    outline: none;
    text-transform: none;

    &.btn-link-anchor {
      background: none;
      background-color: none;
      border: none;
      border-radius: 0;
      box-shadow: none;
      cursor: pointer;
      display: inline;
      font-size: inherit;
      font-weight: $font-weight;
      height: auto;
      line-height: $line-height;
      max-width: none;
      outline: none;
      overflow: hidden;
      padding: 0;
      text-align: inherit;
      text-decoration: none;
      text-overflow: ellipsis;
      text-transform: none;
      vertical-align: initial;
      word-wrap: normal;

      &.muted {
        &:hover,
        &:focus {
          color: $brand-primary;
        }
      }
    }

    &.btn-link-icon {
      background-color: none;
      border: none;
      border-radius: 0;
      box-shadow: none;
      color: $brand-link-primary-color;
      cursor: pointer;
      display: inline;
      font-size: inherit;
      font-weight: $font-weight;
      height: auto;
      line-height: $line-height;
      outline: none;
      overflow: hidden;
      padding: 0;
      text-align: center;
      text-decoration: none;
      text-overflow: ellipsis;
      text-transform: none;
      vertical-align: initial;

      .fa {
        color: $brand-link-primary-color;
      }
    }

    &:hover,
    &:focus {
      color: color.adjust($brand-link-primary-color, $lightness: -$contrast);
      text-decoration: underline;
    }
  }

  &.btn-primary {
    background-color: $brand-button-continue-color;
    box-shadow: 0 2px 0 0
      color.adjust($brand-button-continue-color, $lightness: $contrast-low);

    &:hover,
    &:focus,
    &:active {
      background-color: color.adjust($brand-button-continue-color,
      $lightness: $contrast-low);
    }
  }

  &.btn-warning {
    background-color: $brand-button-highlight-color;
    box-shadow: 0 2px 0 0
      color.adjust($brand-button-highlight-color, $lightness: $contrast-low);

    &:hover,
    &:focus,
    &:active {
      background-color: color.adjust($brand-button-highlight-color,
      $lightness: $contrast-low);
    }
  }

  &.btn-help {
    background-color: transparent;
    box-shadow: none;
    font-size: $font-size-large + $font-size-nudge;
    padding: 0;
    width: 100%;

    &,
    .fa {
      color: $brand-button-normal-color;
    }

    &:hover,
    &:focus,
    &:active {
      background-color: transparent;
      outline: 0;

      &,
      .fa {
        color: color.adjust($brand-button-normal-color,
        $lightness: -$contrast-low);
      }
    }
  }

  &a:not([href]),
  &[disabled] {
    background-color: color.adjust($brand-button-disabled-color,
    $lightness: -$contrast);
    box-shadow: inset 0 1px 0 0 $brand-button-disabled-color;
    cursor: default;
    pointer-events: none;

    &:hover,
    &:focus,
    &:active {
      background-color: color.adjust($brand-button-disabled-color,
      $lightness: -$contrast);
      pointer-events: none;
    }
  }
}

// placements

.btn-hub {
  bottom: 10px;
  position: absolute;
  right: 10px;
}

.btn-will {
  font-size: 24px;
  height: 40px;
  line-height: 40px;
}
