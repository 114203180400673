@use 'sass:color';
@use 'sass:math';

// Raleway Font Face
@import 'https://fonts.googleapis.com/css?family=Raleway:400,300,500';

body {
  color: $brand-text-primary-color;
  font: $font-stack;
  -webkit-font-smoothing: subpixel-antialiased;
  -webkit-tap-highlight-color: white(0);
  text-rendering: optimizelegibility;
  text-shadow: 0 1px 0 $brand-text-shadow-color;
}

a {
  color: $brand-link-primary-color;
  outline: none;
  text-decoration: none;

  &:hover,
  &:focus {
    color: color.adjust($brand-link-primary-color, $lightness: -$contrast);
    text-decoration: underline;
  }

  &.muted {
    color: $brand-text-primary-color;

    &:hover,
    &:focus {
      color: $brand-primary;
    }
  }
}

@each $heading, $heading-size in $font-headings {
  #{$heading} {
    color: color.adjust($brand-text-primary-color, $lightness: -$contrast);
    font-size: $heading-size;
    font-weight: $font-weight-light;
    margin-bottom: $font-size-small;
    margin-top: $font-size;
    text-shadow: 0 1px 0 $brand-text-shadow-color;
  }
}

p {
  margin-bottom: 1em;
  margin-top: 1em;
  text-shadow: 0 1px 0 $brand-text-shadow-color;

  &.small {
    font-size: 11px;
    line-height: 0.9;
    margin-bottom: 10px;
  }

  &.lead {
    font-size: math.floor(($font-size * 1.15));
    font-weight: $font-weight-light;
    line-height: $line-height;

    @media (min-width: $screen-sm-min) {
      font-size: ($font-size * 1.5);
    }
  }
}

small {
  font-size: $font-size-small;
}

hr {
  border: none;
  border-top: 1px solid $brand-border-secondary-color;
  padding: 0;
}

.highlight {
  color: $brand-text-highlight-color;

  a {
    color: $brand-link-highlight-color;
    text-decoration: underline;

    &:hover,
    &:focus {
      color: color.adjust($brand-red, $lightness: -$contrast-low);
    }
  }
}

.freemium-label {
  color: $brand-primary;
  font-size: 16px;
  font-weight: 200;

  &.done {
    color: $brand-icon-complete-color;

    span {
      border-color: $brand-icon-complete-color;
    }
  }

  > span {
    border: 2px solid;
    border-radius: 10px;
    display: inline-block;
    font-size: $font-size-large + $font-size-nudge;
    height: auto;
    line-height: initial;
    min-width: 38px;
    padding: 3px;
    text-align: center;
    vertical-align: middle;
    width: 100%;
  }

  .fa-ban {
    left: 0;
    text-align: center;
    width: 100%;
  }
}

.wishes-section {
  .freemium-label {
    margin-top: 0;

    span {
      margin-top: 15px;
    }
  }
}
