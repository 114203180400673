@use 'sass:color';

.wishes-wrapper {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border-radius: 0;
  background-color: white;

  .wishes-progress {
    padding: 13px;
  }

  .people,
  .assets {
    margin-right: $grid-gutter-width * 0.5;
    margin-left: $grid-gutter-width * 0.5;
    background: $brand-page-background-color;
    text-align: center;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
    .item {
      display: inline-block;
      margin-bottom: 8px;
      padding: 0;
      width: 75px;
      height: 90px;
      background: white;
      box-shadow: 0 1px 1px
        color.adjust($brand-card-box-shadow-color, $lightness: -$contrast);
      &:hover {
        position: relative;
        top: -2px;
        box-shadow: 0 1px 1px
          color.adjust(
            $brand-card-box-shadow-color,
            $lightness: -$contrast-high
          );
        cursor: move;
      }
      > a {
        display: block;
      }
      .img-container {
        overflow: hidden;
        width: 100%;
        height: 70px;
        .fa-plus {
          font-size: 70px;
          color: color.adjust($brand-primary, $lightness: $contrast-low);
          cursor: pointer;
        }
      }
      .label {
        display: block;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-transform: capitalize;
        padding: 0;
        margin: 2px;
        font-size: 14px;
        color: $brand-text-primary-color;
      }
    }
  }

  .wishes-prose {
    .inner {
      &.bordered {
        border-bottom: 1px solid $brand-secondary;
        border-top: 1px solid $brand-secondary;
      }
      &.border-bottom {
        border-bottom: 1px solid $brand-secondary;
      }
      &.border-top {
        border-top: 1px solid $brand-secondary;
      }
    }
    .guidance {
      padding: 0 20px;
      display: block;
      margin-left: 0;
      margin-right: 0;
    }
    .summary {
      .button-column {
        min-width: 220px;
      }
      .no-gifts {
        padding-top: 25%;
        .large-text {
          text-align: center;
          font-size: $font-size-large + ($font-size-nudge * 5);
        }
        .text {
          text-align: center;
        }
      }
    }
  }

  .person,
  .asset {
    .inner {
      display: grid;
      place-content: center;
      justify-items: center;
      padding: $grid-gutter-width * 0.5;
      background-color: color.adjust(
        $brand-border-primary-color,
        $lightness: $contrast
      );
      background-image: url('../img/furniture/fa-plus.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: 50px 50px;

      .cards {
        display: flex;
        justify-content: center;
      }

      &.appointed,
      &.no-background {
        background-image: none;
      }
      &.bordered {
        border-bottom: 1px solid $brand-secondary;
        border-top: 1px solid $brand-secondary;
      }
      &.border-bottom {
        border-bottom: 1px solid $brand-secondary;
      }
      &.border-top {
        border-top: 1px solid $brand-secondary;
      }
      &.border-right-dashed {
        border-right: 1px dashed black;
      }
      &.empty {
        background-color: color.adjust(
          $brand-border-primary-color,
          $lightness: $contrast
        );
        text-align: center;
        padding: 80px 50px 0;
      }

      .info {
        max-width: 30ch;
        color: color.adjust(
          $brand-text-primary-color,
          $lightness: -$contrast-high
        );
        text-align: center;
        font-size: 22px;
        font-weight: 400;
        position: relative;
        z-index: 1;

        &.detail {
          font-size: 18px;
        }
      }

      a {
        color: color.adjust($brand-link-primary-color, $lightness: -$contrast);
        &:hover,
        &:focus {
          color: $brand-link-primary-color;
        }
        &.btn {
          color: white;
        }
      }
    }
  }
  .asset {
    .inner {
      text-align: left;
      &.empty {
        text-align: center;
        padding: 30px 50px 0;
      }
    }
  }
  .bg-icon {
    font-size: 60px;
    position: absolute;
    margin-left: -20px;
    margin-top: -30px;
    left: 50%;
    top: 50%;
    color: black(0.05);
  }
}

.wishes-creator {
  display: flex;
  flex-direction: column;
  flex: 1;

  .person,
  .asset {
    display: flex;
    flex-direction: column;
    flex: 1;

    .inner {
      flex: 1;
    }
  }
}

.input-group-btn {
  .btn {
    box-shadow: none;
  }
}

.item {
  .img-container {
    background-color: $brand-icon-primary-color;
  }
}

@media screen and (min-width: $screen-sm-min) {
  .wishes-wrapper {
    flex: auto;

    .people,
    .assets {
      margin-right: 0;
      margin-left: 0;

      border-style: solid;
      border-color: $brand-secondary;
      border-top: none;
      border-bottom: none;

      &.left {
        border-left: none;
        border-right-width: 1px;
      }

      &.right {
        border-right: none;
        border-left-width: 1px;
      }
    }

    .people {
      border-left-width: 1px;
    }
    .assets {
      border-right-width: 1px;
    }

    .person,
    .asset {
      .inner {
        .info {
          &.detail {
            font-size: 16px;
          }
        }
      }
    }
  }

  .wishes-creator {
    display: grid;
    grid-template-areas:
      'left progress'
      'left appoint'
      'left footer';
    grid-template-rows: 80px minmax(440px, 1fr) 80px;
    grid-template-columns: 16% 1fr;

    .wishes-progress {
      grid-area: progress;
    }

    .people,
    .assets {
      &.left {
        grid-area: left;
      }
      &.right {
        grid-area: right;
      }
    }

    .person,
    .asset {
      grid-area: appoint;
    }

    .footer-text {
      grid-area: footer;
    }

    &.multiselect {
      grid-template-areas:
        'left progress progress right'
        'left gift appoint right'
        'left footer footer right';
      grid-template-columns: 16% 1fr 1fr 16%;

      .asset {
        grid-area: gift;
      }

      .person {
        grid-area: appoint;
      }
    }
  }
}

@media screen and (min-width: $screen-md-min) {
  .wishes-creator {
    grid-template-rows: 80px minmax(440px, 60vh) 80px;
    grid-template-columns: 12% 1fr;

    &.multiselect {
      grid-template-columns: 12% 1fr 1fr 12%;
    }
  }
}

@media screen and (min-width: $screen-lg-min) {
  .wishes-creator {
    grid-template-columns: 8% 1fr;

    &.multiselect {
      grid-template-columns: 8% 1fr 1fr 8%;
    }
  }
}
