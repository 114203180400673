@use 'sass:color';

form,
ng-form {
  margin: 0;
  padding: 0;
  text-align: center;

  .fa {
    color: $brand-icon-primary-color;

    &.fa-exclamation,
    &.fa-exclamation-circle {
      color: $brand-icon-highlight-color;
    }

    &.fa-inverse {
      color: white;
    }
  }
}

ng-form {
  display: block;
}

.addressForm {
  .label-container {
    text-align: right;
  }

  .input-container {
    text-align: left;
  }
}

.form-wrapper {
  background: $brand-form-background-color;
  border: 1px solid $brand-form-border-color;
  border-radius: 2px;
  padding: 25px 50px;

  @media screen and (max-width: $screen-sm-max) {
    padding: 15px;
    width: 100%;
  }

  ul {
    margin-left: 10px;
    text-align: left;
  }

  textarea {
    margin-bottom: 15px;
    width: 100%;
  }
}

.form-control-static {
  margin-top: 0;
}

input[type='text'],
input[type='tel'],
input[type='email'],
input[type='number'],
input[type='password'],
textarea,
select {
  background: white;
  border: 1px solid
    color.adjust($brand-form-border-color, $lightness: -$contrast-low);
  border-radius: 2px;
  box-sizing: border-box;
  display: inline-block;
  font-family: $font-stack;
  font-size: 14px;
  outline: none;
  padding: 0 5px;
  vertical-align: middle;

  &:focus {
    border-color: color.adjust($brand-form-border-color,
    $lightness: -$contrast-low);
  }

  &.error,
  &.ng-dirty.ng-invalid {
    border-color: $brand-form-invalid-color;
    border-width: 2px;
    outline: none;
    padding: 0 4px;
  }
}

input[type='file'] {
  width: 100%;
}

select {
  min-width: 100px;
}

label,
.radio label,
.checkbox label {
  @include noselect;

  color: $brand-text-primary-color;

  // for compatibility with Bootstrap form layout
  font-size: $font-size-large - $font-size-nudge * 2;
  font-weight: $font-weight-light;
  vertical-align: middle;
}

.uploadCtrl,
.cancelCtrl,
.removeCtrl {
  margin-bottom: 16px;
  text-align: center;
}

.icon-input {
  position: relative;

  > input,
  > textarea,
  > select {
    background: transparent;
    padding-left: 30px;

    &.error,
    &.ng-dirty.ng-invalid {
      padding-left: 29px;
    }
  }

  > .fa {
    color: $brand-text-primary-color;
    font-size: 15px;
    left: 7px;
    position: absolute;
    text-align: center;
    top: 9px;
    width: 20px;
  }
}

.stretch {
  display: inline-block;
  width: 100%;
  width: calc(100% - 1px);
}

#userBirthday,
#userBirthmonth,
#userBirthyear {
  width: 60px;
}

#userAddressLine1,
#userAddressLine2,
#userAddressCity,
#userAddressRegion,
#userAddressPostCode,
#userAddressCountry {
  width: 100%;
}
