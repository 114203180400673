@use 'sass:color';

.progress {
  margin-left: -8.33333%;
  margin-right: -8.33333%;

  @media screen and (max-width: $screen-xs-max) {
    margin-bottom: 25px !important;
    margin-left: 0%;
    margin-right: 0%;
  }
}

.progress-col {
  color: $brand-link-disabled-color;
  float: left;
  position: relative;
  text-align: center;

  .num {
    background: color.adjust($brand-icon-disabled-color,
    $lightness: $contrast-low);
    border-radius: 100%;
    display: inline-block;
    font-weight: 600;
    height: 25px;
    line-height: 22px;
    margin-bottom: 10px;
    position: relative;
    text-align: center;
    width: 25px;
    z-index: 10;
  }

  .name {
    font-weight: 400;
    text-transform: uppercase;
  }

  &::before {
    background: color.adjust($brand-icon-disabled-color,
    $lightness: $contrast-low);
    content: '';
    height: 5px;
    left: -50%;
    position: absolute;
    right: 50%;
    top: 11px;
    width: 100%;
  }

  &:first-child {
    &::before {
      display: none;
    }
  }

  &.active,
  &.done {
    .num,
    &::before {
      background: $brand-icon-complete-color;
      color: white;
    }

    .name {
      color: $brand-icon-complete-color;
    }
  }

  &.progress-col-fifth {
    width: 20%;
  }
}

.form-input {
  display: inline-block;
  margin-bottom: 10px;
  margin-right: 10px;
  white-space: nowrap;

  label {
    margin-right: 3px;

    @media screen and (max-width: $screen-xs-max) {
      display: block;
      margin-right: 0;
    }
  }
}
