.pull-left {
  float: left;
}
.pull-right {
  float: right;
}
.pull-none {
  float: none;
}

.push-left {
  position: absolute;
  right: auto;
  left: 0;
}
.push-right {
  position: absolute;
  right: 0;
  left: auto;
}

.m-small {
  margin: 3px;
}
.mt-small {
  margin-top: 3px;
}
.mr-small {
  margin-right: 3px;
}
.mb-small {
  margin-bottom: 3px;
}
.ml-small {
  margin-left: 3px;
}

.m05t {
  margin-top: 5px;
}

.m1 {
  margin: 10px;
}
.m1t {
  margin-top: 10px;
}
.m1r {
  margin-right: 10px;
}
.m1b {
  margin-bottom: 10px;
}
.m1l {
  margin-left: 10px;
}

.m2 {
  margin: 20px;
}
.m2t {
  margin-top: 20px;
}
.m25t {
  margin-top: 25px;
}
.m2r {
  margin-right: 20px;
}
.m2b {
  margin-bottom: 20px;
}
.m2l {
  margin-left: 20px;
}

.m3t {
  margin-top: 30px;
}
.m35t {
  margin-top: 35px;
}
.m3r {
  margin-right: 35px;
}
.m3b {
  margin-bottom: 35px;
}
.m3l {
  margin-left: 35px;
}

.m5 {
  margin: 50px;
}
.m5t {
  margin-top: 50px;
}
.m5r {
  margin-right: 50px;
}
.m5b {
  margin-bottom: 50px;
}
.m5l {
  margin-left: 50px;
}

.m10 {
  margin: 100px;
}
.m10t {
  margin-top: 100px;
}
.m10r {
  margin-right: 100px;
}
.m10b {
  margin-bottom: 100px;
}
.m10l {
  margin-left: 100px;
}

.block {
  display: block !important;
}
.inline-block {
  display: inline-block !important;
}
.inline {
  display: inline !important;
}

.clear {
  clear: both;
}
.nowrap {
  white-space: nowrap;
}
.centered {
  text-align: center;
  img {
    margin-right: auto;
    margin-left: auto;
  }
}

.pointer {
  cursor: pointer;
}

.move {
  cursor: move;
}

.scroller {
  overflow: hidden;
  overflow-y: scroll;
}

.tab {
  @include leadingTab();
}

.no-number-spinners {
  -moz-appearance: textfield;
  -webkit-appearance: textfield;
  appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Only display content to screen readers
//
// See: http://a11yproject.com/posts/how-to-hide-content/

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: none;
}

// Use in conjunction with .sr-only to only display content when it's focused.
// Useful for "Skip to main content" links; see http://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
// Credit: HTML5 Boilerplate

.sr-only-focusable {
  &:active,
  &:focus {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    clip: auto;
  }
}

.scroller {
  overflow: hidden;
  overflow-y: scroll;
}

.help {
  .article > li {
    font-size: 18px;
  }
}
