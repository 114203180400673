.relationships-list {
  padding-bottom: $grid-gutter-width;
}

.relationships-tree-wrapper {
  overflow: hidden;
}

.relationships-tree-header {
  background: white(1);
}

.wrapper__controls {
  .status {
    color: $brand-link-primary-color;
    font-size: $font-size-large;
    line-height: 1.7;
  }
}

.tutorial {
  color: $brand-link-primary-color;
  cursor: pointer;
  font-size: 18px;
  height: 48px;
  left: 0;
  line-height: 44px;
  margin-bottom: 11px;
  margin-top: 14px;
  padding-right: 25px;
  position: absolute;
  text-align: right;
  top: 0;
  user-select: none;
  width: 100%;
  z-index: 2;
}

.add-person-column {
  padding-left: 8.3%;

  p {
    font-size: $font-size-large;
  }
}
